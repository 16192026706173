import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import styled from '@emotion/styled'

import mq from 'styles/breakpoints'
import { useAuth0 } from 'utils/react-auth0-spa'
import UserContext from 'context/user/UserContext'
import logo from 'images/png/fluentworlds_banner.png'
import UserAuth from 'components/UserAuth'
import UserAuthNavLinks from 'components/UserAuthNavLinks'
import { ENGLISH, SPANISH, TURKISH, PORTUGUESE } from 'utils/supportedLanguages'
import {
  NavbarContainer,
  NavCenter,
  NavHeader,
  NavLinksWrapper,
  LogoButton,
  BurgerIcon,
  SelectContainer,
  LoadingIndicatorSignIn,
  LoadingIndicatorSignUp,
} from 'components/HeaderComponents'

const Header = () => {
  const [isOpen, setNav] = useState(false)
  const { user, auth0Loading } = useAuth0()
  const { username, fetchUserFromFirestore, userStateLoading } = useContext(
    UserContext,
  )
  const { t, i18n, ready } = useTranslation('header')

  const options = [
    { value: ENGLISH.code, label: ENGLISH.label },
    { value: SPANISH.code, label: SPANISH.label },
    { value: TURKISH.code, label: TURKISH.label },
    { value: PORTUGUESE.code, label: PORTUGUESE.label },
  ]

  const initializeSelectedLanguageLabel = () => {
    try {
      return options.find(
        (option) => option.value === selectedLanguage.substring(0, 2),
      ).label
    } catch (error) {
      console.error(error)
    }
  }

  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language)
  const [selectedLanguageLabel, setSelectedLanguageLabel] = useState(
    initializeSelectedLanguageLabel(),
  )

  // change the language
  useEffect(() => {
    i18n.changeLanguage(selectedLanguage)
  }, [selectedLanguage, i18n])

  const handleChange = (lang) => {
    setSelectedLanguage(lang.value)
    setSelectedLanguageLabel(lang.label)
  }

  // when user is authenticated, fetch the user from DB
  useEffect(() => {
    if (user && username === '') {
      fetchUserFromFirestore()
    }
    // eslint-disable-next-line
  }, [user])

  const toggleNav = () => {
    setNav(!isOpen)
  }

  const NavLinks = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    background-color: transparent;
    list-style-type: none;
    transition: all 0.3s linear;
    font-size: 2.5rem;
    text-decoration: none;
    text-align: center;
    height: 0;

    a {
      color: #213054;
      display: block;
      padding: 1rem 1.25rem;
      text-decoration: none;
      text-transform: capitalize;
      transition: all 0.3s linear;
      font-weight: bold;
      text-decoration: none;

      ${mq['xxl']} {
        max-width: unset;
        font-size: 2rem;
        text-transform: capitalize;
      }
    }

    ${mq['xl']} {
      display: flex;
      justify-content: space-between;
      width: 80%;
      margin-right: 13rem;
    }

    ${mq['lg']} {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      text-align: left;
      overflow: hidden;
      background-color: rgb(255, 255, 255, 0.75);
    }

    @media screen and (min-width: 992px) {
      height: auto;
      display: flex;
      list-style-type: none;
    }

    ${isOpen ? 'height: 32rem' : ''}
  `

  return (
    <NavbarContainer>
      <NavCenter>
        <NavHeader>
          <Link to="/">
            <img src={logo} alt="fluentworlds.com" />
          </Link>
          <LogoButton onClick={toggleNav}>
            <BurgerIcon />
          </LogoButton>
        </NavHeader>
        <NavLinksWrapper>
          <NavLinks>
            <li>
              <Link
                to="/"
                activeStyle={{ color: '#0080fa', fontWeight: 'bold' }}
              >
                {t('home')}
              </Link>
            </li>
            <li>
              <Link
                to="/products"
                activeStyle={{ color: '#0080fa', fontWeight: 'bold' }}
              >
                {t('products')}
              </Link>
            </li>
            <li>
              <Link
                to="/blog/blog"
                activeStyle={{ color: '#0080fa', fontWeight: 'bold' }}
              >
                {t('blog')}
              </Link>
            </li>
            <li>
              <Link
                to="/become-ambassador"
                activeStyle={{ color: '#0080fa', fontWeight: 'bold' }}
              >
                {t('ambassador')}
              </Link>
            </li>

            <SelectContainer>
              <Select
                value={selectedLanguage}
                placeholder={selectedLanguageLabel}
                isLoading={!ready}
                onChange={handleChange}
                options={options}
                menuPortalTarget={
                  typeof window !== 'undefined' ? document.body : null
                }
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              />
            </SelectContainer>

            {auth0Loading || !ready ? (
              LoadingIndicatorSignIn
            ) : (
              <UserAuthNavLinks />
            )}
          </NavLinks>

          {auth0Loading || userStateLoading || !ready ? (
            LoadingIndicatorSignUp
          ) : (
            <UserAuth />
          )}
        </NavLinksWrapper>
      </NavCenter>
    </NavbarContainer>
  )
}

export default Header
