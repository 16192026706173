import React from 'react'
import { Helmet } from 'react-helmet'
import { Global } from '@emotion/react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import GlobalStyles from 'styles/GlobalStyles'
import Header from 'components/Header'
import Footer from 'components/Footer'
import { MainContents } from 'components/MainContents'

const Layout = ({ children, showHeader = true, showFooter = true }) => {
  return (
    <>
      <Global styles={GlobalStyles} />
      <main>
        <Helmet>
          <script
            src="https://script.tapfiliate.com/tapfiliate.js"
            type="text/javascript"
            async
          ></script>
          <script type="text/javascript">
            {`
            (function(t,a,p){t.TapfiliateObject=a;t[a]=t[a]||function(){
              (t[a].q=t[a].q||[]).push(arguments)}})(window,'tap');

              tap('create', '13057-f3c134', { integration: "stripe" });
              tap('detect');
          `}
          </script>
        </Helmet>
        {showHeader && <Header />}
        <MainContents>{children}</MainContents>
        <ToastContainer
          limit={1}
          position="bottom-left"
          autoClose={8000}
          hideProgressBar={true}
          style={{ fontSize: '1.5rem' }}
        />
        {showFooter && <Footer />}
      </main>
    </>
  )
}

export default Layout
